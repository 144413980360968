<template>
  <action-dispatcher action="opportunity/get" :parameters="$route.query">
    <div class="view-opportunities">
      <div class="container">
        <div class="d-flex align-items-start">
          <div class="flex-fill">
            <h2 class="page-title text-center text-lg-left text-uppercase">
              Oportunidades
            </h2>
            <p
              class="page-description text-center text-lg-left text-light mb-5"
            >
              Encontre o projeto de uma empresa parceira para começar a
              executar.
            </p>
          </div>
          <div class="d-none d-lg-block">
            <router-link
              :to="{ name: 'new-opportunity' }"
              class="btn btn-primary"
            >
              Publicar oportunidade
            </router-link>
          </div>
        </div>
        <div class="d-flex d-lg-none justify-content-center mb-4">
          <router-link
            :to="{ name: 'new-opportunity' }"
            class="btn btn-primary"
          >
            Publicar oportunidade
          </router-link>
        </div>
        <div class="row">
          <div class="col-lg-3 mb-4 mb-lg-0">
            <filter-box
              :options="categories"
              v-model="selected_categories"
              allow-search
              @on-empty="onEmptyFilters"
            />
          </div>
          <div class="col-lg-9">
            <div class="row mb-4">
              <div class="col-lg-8 mb-3 mb-lg-0">
                <search-input
                  placeholder="Pesquisar"
                  v-model="paramsFilter.q"
                />
              </div>
              <div class="col-lg-4">
                <order-by v-model="paramsFilter.order_by" />
              </div>
            </div>
            <div v-if="selected_categories.length" class="mb-4">
              <h3 class="text-uppercase mb-3">
                Resultados para "{{
                  selected_categories.map(c => id_name_map[c]).join(", ")
                }}"
              </h3>
              <div v-for="(cat, i) in selected_categories" :key="`cat-${i}`">
                <b-form-group
                  v-model="selected_tags"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-form-checkbox-group
                    :id="`checkbox-group-${i}`"
                    class="font-weight-bold"
                    :options="
                      normalizedTags[normalizeCat(cat)].map(text => ({ text }))
                    "
                    :aria-describedby="ariaDescribedby"
                    :name="`flavour-${i}`"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <hr />
            </div>
            <div v-if="opportunities.length">
              <div
                v-for="(opportunity, index) in opportunities"
                :key="`opportunity-${index}`"
                class="mb-4"
              >
                <opportunities-item
                  :opportunity="opportunity"
                  @on-new-proposal="showNewProposal"
                />
              </div>

              <paginator
                v-model="paramsFilter.page"
                :pagination="pagination"
                class="mb-5"
              />
            </div>
            <div v-else class="d-flex justify-content-center pt-4">
              <h4 class="text-secondary">
                Não há oportunidades disponíveis no momento
              </h4>
            </div>
          </div>
        </div>
        <div class="d-lg-none py-5"></div>
      </div>

      <!-- Modal -->
      <new-proposal
        v-model="new_proposal_show"
        :opportunity="current_opportunity"
        @close-modal="new_proposal_show = false"
        @update-proposals="updateProposals"
      />
      <!-- Modal End -->
    </div>
  </action-dispatcher>
</template>
<script>
import OpportunitiesItem from "@/components/opportunity/Item.vue";
import NewProposal from "@/components/proposal/New.vue";
import OrderBy from "@/components/filters/OrderBy.vue";
import { normalizeText, clearObject } from "@/functions/helpers";
import FilterBox from "@/components/FilterBox.vue";
import { paramsMixin } from "@/mixins";
import { mapState } from "vuex";

export default {
  mixins: [paramsMixin],
  components: { OpportunitiesItem, FilterBox, NewProposal, OrderBy },
  data() {
    const selected_categories = this.$route.query?.category_id__in
      ? this.$route.query.category_id__in.split(",")
      : [];

    return {
      selected_categories,
      selected_tags: [],
      current_opportunity: null,
      new_proposal_show: false,
      paramsFilter: {
        category_id__in: "",
        status__in: "",
        status: "",
        name__icontains: "",
        title__contains: "",
        order_by: "",
        q: "",
        page: 1,
        ...this.$route.query
      }
    };
  },
  mounted() {
    if (!this.categories.length)
      this.$store.dispatch("professional/getCategories").then();
    this.updateNameMap();
  },
  watch: {
    paramsFilter: {
      deep: true,
      handler(newVal) {
        this.$router.push({
          ...this.$route,
          query: {
            ...clearObject(this.$route.query),
            ...clearObject(newVal)
          }
        });
      }
    },
    selected_categories: {
      deep: true,
      handler(newVal) {
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            category_id__in: [...newVal].join(",")
          }
        });
      }
    }
  },
  computed: {
    ...mapState("opportunity", ["opportunities", "pagination"]),
    ...mapState("professional", ["categories", "tags"])
  },
  methods: {
    onEmptyFilters() {
      this.$router.push({
        ...this.$route,
        query: null
      });
    },
    updateProposals() {
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query
        }
      });
    },
    normalizeCat(v) {
      return normalizeText(v);
    },
    submitFilter(v) {},
    clearFilter(v) {},
    showNewProposal(p) {
      this.current_opportunity = p;
      this.new_proposal_show = true;
    }
  }
};
</script>
<style lang="scss" scoped>
.view-opportunities {
}
</style>
